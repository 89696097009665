import classnames from "classnames";

interface ButtonPrimaryProps {
  size?: "l" | "s";
}

function ButtonPrimary({
  size = "l",
  className,
  ...props
}: ButtonPrimaryProps & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      className={classnames(
        { "size-s": size === "s" },
        "bg-purple100 text-whitefff",
        "focus-visible:outline-none focus-visible:ring focus-visible:ring-purple100 focus-visible:ring-opacity-50",
        className
      )}
      {...props}
    />
  );
}

export default ButtonPrimary;
