import React from "react";
import classnames from "classnames";
import { Link } from "@remix-run/react";
import type { Location } from "react-router";

const renderChecklistItems = (
  checkedItems: number[],
  location: Location,
  internship: boolean
) => {
  const licenseSupervisionInterchangeableLink = internship ? (
    <Link
      to={"/dashboard/supervision"}
      key={"/dashboard/supervision"}
      data-testid={"list-item-link-supervision"}
      className={classnames({
        "text-purple100": location.pathname === "/dashboard/supervision",
      })}
    >
      <span>{"Supervision"}</span>
    </Link>
  ) : (
    <Link
      to={"/dashboard/licenses"}
      key={"/dashboard/licenses"}
      data-testid={"list-item-link-licenses"}
      className={classnames({
        "text-purple100": location.pathname === "/dashboard/licenses",
      })}
    >
      <span>{"Licenses"}</span>
    </Link>
  );

  return {
    items: [
      <Link
        to={"/dashboard/general"}
        key={"/dashboard/general"}
        data-testid={"list-item-link-general"}
        className={classnames({
          "text-purple100": location.pathname === "/dashboard/general",
        })}
      >
        <span>{"General Information"}</span>
      </Link>,

      <Link
        to={"/dashboard/identity"}
        key={"/dashboard/identity"}
        data-testid={"list-item-link-identity"}
        className={classnames({
          "text-purple100": location.pathname === "/dashboard/identity",
        })}
      >
        <span>{"Identity"}</span>
      </Link>,
      <Link
        to={"/dashboard/general-office-hours"}
        key={"/dashboard/general-office-hours"}
        data-testid={"list-item-link-general-office-hours"}
        className={classnames({
          "text-purple100":
            location.pathname === "/dashboard/general-office-hours",
        })}
      >
        <span>{"General Office Hours"}</span>
      </Link>,
      <Link
        to={"/dashboard/fees-and-types-of-therapy"}
        key={"/dashboard/fees-and-types-of-therapy"}
        data-testid={"list-item-link-fees-and-types-of-therapy"}
        className={classnames({
          "text-purple100":
            location.pathname === "/dashboard/fees-and-types-of-therapy",
        })}
      >
        <span>{"Fees and Insurances"}</span>
      </Link>,
      <Link
        to={"/dashboard/practice-details"}
        key={"/dashboard/practice-details"}
        data-testid={"list-item-link-practice-details"}
        className={classnames({
          "text-purple100": location.pathname === "/dashboard/practice-details",
        })}
      >
        <span>{"Practice Details"}</span>
      </Link>,
      <Link
        to={"/dashboard/specialties-and-expertise"}
        key={"/dashboard/specialties-and-expertise"}
        data-testid={"list-item-link-specialties-and-expertise"}
        className={classnames({
          "text-purple100":
            location.pathname === "/dashboard/specialties-and-expertise",
        })}
      >
        <span>{"Specialties and Expertise"}</span>
      </Link>,
      <Link
        to={"/dashboard/education-and-certifications"}
        key={"/dashboard/education-and-certifications"}
        data-testid={"list-item-link-education-and-certifications"}
        className={classnames({
          "text-purple100":
            location.pathname === "/dashboard/education-and-certifications",
        })}
      >
        <span>{"Education and Certifications"}</span>
      </Link>,
      <Link
        to={"/dashboard/questions"}
        key={"/dashboard/questions"}
        data-testid={"list-item-link-questions"}
        className={classnames({
          "text-purple100": location.pathname === "/dashboard/questions",
        })}
      >
        <span>{"Therapist Profile Questions"}</span>
      </Link>,
      <Link
        to={"/dashboard/gallery"}
        key={"/dashboard/gallery"}
        data-testid={"list-item-link-gallery"}
        className={classnames({
          "text-purple100": location.pathname === "/dashboard/gallery",
        })}
      >
        <span>{"Photo Gallery"}</span>
      </Link>,
      <Link
        to={"/dashboard/videos"}
        key={"/dashboard/videos"}
        data-testid={"list-item-link-videos"}
        className={classnames({
          "text-purple100": location.pathname === "/dashboard/videos",
        })}
      >
        <span>{"Videos"}</span>
      </Link>,
      licenseSupervisionInterchangeableLink,
    ].filter(Boolean),
    checkedItems,
  };
};

export default renderChecklistItems;
