import React from "react";
import classnames from "classnames";
import {
  Facebook as FacebookDesktop,
  Linkedin as LinkedinDesktop,
  Pinterest as PinterestDesktop,
  Tiktok as TiktokDesktop,
  Twitter as TwitterDesktop,
  Youtube as YoutubeDesktop,
} from "../../1-atoms/Icons/20 ";
import {
  Facebook as FacebookMobile,
  Linkedin as LinkedinMobile,
  Pinterest as PinterestMobile,
  Tiktok as TiktokMobile,
  Twitter as TwitterMobile,
  Youtube as YoutubeMobile,
} from "../../1-atoms/Icons/32 ";
import {
  Instagram20 as InstagramDesktop,
  Instagram32 as InstagramMobile,
} from "../../1-atoms/Icons";
import { mockProps } from "./fixtures";
import { ArrowS } from "../../1-atoms/Icons/16 ";
import type { GetFooterDataQuery } from "~/generated/graphql";
import TiktokNew from "~/UI/1-atoms/Icons/20 /TiktokNew";
import FacebookNew from "~/UI/1-atoms/Icons/20 /FacebookNew";
import SvgTwitterNew from "~/UI/1-atoms/Icons/20 /TwitterNew";
import LinkedinNew from "~/UI/1-atoms/Icons/20 /LinkedinNew";
import InstagramNew from "~/UI/1-atoms/Icons/20 /InstagramNew";
import PinterestNew from "~/UI/1-atoms/Icons/20 /PinterestNew";
import YoutubeNew from "~/UI/1-atoms/Icons/20 /YoutubeNew";

export const socialMediaTypes = [
  "facebook",
  "twitter",
  "linkedin",
  "instagram",
  "pinterest",
  "youtube",
  "tikTok",
] as const;

const renderSocialMediaIcon = (icon: typeof socialMediaTypes[number]) => {
  switch (icon) {
    case 'tikTok':
      return {
        icon: <TiktokNew aria-label="ChoosingTherapy.com on TikTok" role="img" />,
        label: 'ChoosingTherapy.com on TikTok',
      };
    case 'facebook':
      return {
        icon: <FacebookNew aria-label="ChoosingTherapy.com on Facebook" role="img" />,
        label: 'ChoosingTherapy.com on Facebook',
      };
    case 'twitter':
      return {
        icon: <SvgTwitterNew aria-label="ChoosingTherapy.com on Twitter" role="img" />,
        label: 'ChoosingTherapy.com on Twitter',
      };
    case 'linkedin':
      return {
        icon: <LinkedinNew aria-label="ChoosingTherapy.com on LinkedIn" role="img" />,
        label: 'ChoosingTherapy.com on LinkedIn',
      };
    case 'instagram':
      return {
        icon: <InstagramNew aria-label="ChoosingTherapy.com on Instagram" role="img" />,
        label: 'ChoosingTherapy.com on Instagram',
      };
    case 'pinterest':
      return {
        icon: <PinterestNew aria-label="ChoosingTherapy.com on Pinterest" role="img" />,
        label: 'ChoosingTherapy.com on Pinterest',
      };
    case 'youtube':
      return {
        icon: <YoutubeNew aria-label="ChoosingTherapy.com on YouTube" role="img" />,
        label: 'ChoosingTherapy.com on YouTube',
      };
    default:
      return { icon: null, label: '' };
  }
};


export interface PageFooterProps {
  directoryUrl?: string;
  staticPagesLinks?: GetFooterDataQuery["footer"];
  navLinks?: {
    title: string;
    url: string;
  }[];
  socialMediaLinks?: {
    type: (typeof socialMediaTypes)[number];
    url: string;
  }[];
  tosLinks?: {
    title: string;
    url: string;
  }[];
}

function PageFooter({
  className,
  staticPagesLinks = { sections: [] },
  directoryUrl,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & PageFooterProps) {
  const { socialMediaLinks = [], tosLinks = [] } = mockProps;

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  const currentYear = getCurrentYear();
  const linkShouldBeBlankOpened = [
    "Terms of Service",
    "Privacy Policy",
    "Editorial Policy",
    "Advertising Policy",
    "Crisis Hotlines",
    "ChoosingTherapy.com",
    "About us",
  ];

  const navLinks = [
    {
      title: "Contact Us",
      url: `${directoryUrl}/contact-us`,
    },
    {
      title: "Crisis Hotlines",
      url: `https://www.choosingtherapy.com/hotlines/`,
    },
    {
      title: "Therapist Login",
      url: `/login`,
    },
    {
      title: "Therapist Signup",
      url: `/claim-your-profile/create-account`,
    },
    {
      title: "ChoosingTherapy.com",
      url: `https://www.choosingtherapy.com/`,
    },
    {
      title: "About us",
      url: `https://www.choosingtherapy.com/about-us/`,
    },
  ];

  // const { t } = useTranslation();

  return (
    <footer
      data-testid="page-footer"
      className={classnames(
        "typography-3xs-bold page-container flex justify-center bg-purple300 pb-10 text-blackA20 tablet:pb-12 tablet:pt-1 laptop:pb-[3.375rem]",
        className
      )}
      {...props}
    >
      <div
        className={
          "mx-auto mt-9 flex flex-col tablet:max-w-[45.5rem] laptop:max-w-[61.5rem] desktop:max-w-[71.25rem]"
        }
      >
        <div className={"mx-auto w-full"}>
          {staticPagesLinks?.sections.length ? (
            <section>
              <h2 className={"typography-lg mb-5 text-center"}>
                Find the Right Therapist for You
              </h2>
              <div
                className={
                  "gap-x-35 laptop:gap-x-22 grid grid-cols-4 gap-y-10 mobile:grid-cols-2 mobile:gap-x-6 mobile:gap-y-10 tablet:gap-x-7 desktop:gap-x-[8.75rem]"
                }
              >
                {staticPagesLinks.sections.map((section, ix) => (
                  <section key={section.name + ix}>
                    <h3
                      className={
                        "laptop:typography-3xs-bold typography-2xs-bold text-gray250"
                      }
                    >
                      {section.name}
                    </h3>
                    <ul className={"my-2 space-y-2"}>
                      {section.links.map((link, ix) => (
                        <li
                          key={link.name + section.name + ix}
                          className={
                            "laptop:typography-xs-bold typography-2xs-bold text-gray100"
                          }
                        >
                          <a href={link.link}>{link.name}</a>
                        </li>
                      ))}
                    </ul>
                    <a
                      href={section.viewAll.link}
                      className={
                        "laptop:typography-xs-bold typography-2xs-bold text-gray100"
                      }
                    >
                      {section.viewAll.name}{" "}
                      <ArrowS
                        className={"inline -rotate-90 [&_*]:fill-whitefff"}
                      />
                    </a>
                  </section>
                ))}
              </div>
            </section>
          ) : null}
        </div>
        <div className={"mt-9 w-full"}>
          <p
            data-testid="disclaimer"
            className={
              "typography-3xs-bold mb-10 break-normal border-t border-b border-blackA20 py-5 text-left tablet:py-[2rem] tablet:text-center"
            }
          >
            The Choosing Therapy Platform is not appropriate for those in need
            of immediate care. The Suicide & Crisis
            <br className={"tablet:hidden"} />
            Lifeline is{" "}
            <a href="tel:9-8-8" className={"underline underline-offset-2"}>
              9-8-8
            </a>
            .<br /> If you&apos;re experiencing an emergency, call 9-1-1.
          </p>
          <div
            className={
              "mb-10 flex flex-col gap-y-4 mobile:items-center below-laptop:justify-center laptop:grid laptop:grid-cols-[11.25rem_44.75rem] laptop:content-start laptop:gap-x-[5.5rem] desktop:mb-9 desktop:grid-cols-[11.25rem_51.25rem] desktop:gap-x-[8.75rem]"
            }
          >
            <ul
              className={
                "typography-3xs-bold laptop:typography-2xs-bold flex flex-wrap gap-x-4 gap-y-4 mobile:max-w-[15.5rem] below-laptop:justify-center laptop:max-w-[11.25rem] laptop:flex-col laptop:gap-y-3"
              }
            >
              {tosLinks.map((link, ix) => (
                <React.Fragment key={`${link.title}-${ix}`}>
                  <li
                    className={classnames(
                      {
                        "below-laptop:border-r below-laptop:pr-4":
                          ix < tosLinks.length - 1,
                      },
                      "laptop:max-w-[11.25rem]"
                    )}
                    key={`${link.title}-${ix}`}
                  >
                    <a
                      href={link.url}
                      target={
                        linkShouldBeBlankOpened.includes(link.title)
                          ? "_blank"
                          : "_self"
                      }
                      className={
                        "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-blackA20"
                      }
                      data-testid={"toslink"}
                      rel="noreferrer"
                    >
                      {link.title}
                    </a>
                  </li>
                </React.Fragment>
              ))}
            </ul>
            <ul
              className={
                "typography-3xs-bold laptop:typography-2xs-bold flex flex-wrap justify-center gap-x-4 gap-y-4 mobile:max-w-[15.5rem] laptop:grid laptop:max-h-[44px] laptop:grid-flow-col laptop:grid-rows-2 laptop:gap-y-3 laptop:gap-x-[5.5rem] desktop:gap-x-[8.75rem]"
              }
            >
              {navLinks.map((link, ix) => (
                <React.Fragment key={`${link.title}-${ix}`}>
                  <li
                    key={`${link.title}-${ix}`}
                    className={classnames(
                      {
                        "below-laptop:border-r below-laptop:pr-4":
                          ix < navLinks.length - 1,
                      },
                      "laptop:w-[11.25rem]"
                    )}
                  >
                    <a
                      href={link.url}
                      target={
                        linkShouldBeBlankOpened.includes(link.title)
                          ? "_blank"
                          : "_self"
                      }
                      data-testid="navlink"
                      className={
                        "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-blackA20"
                      }
                      rel="noreferrer"
                    >
                      {link.title}
                    </a>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>
          <div
            className={
              "flex flex-col justify-center tablet:flex-row tablet:items-center tablet:justify-between"
            }
          >
            <ul
              className={
                "flex space-x-5 small-mobile:space-x-2 mobile:mb-10 tablet:order-2 below-laptop:justify-center laptop:space-x-4 laptop:pb-2"
              }
            >
              {socialMediaLinks.map((link, ix) => {
                const { icon, label } = renderSocialMediaIcon(link.type);
                return (
                  <li key={`${link.type}-${ix}`}>
                      <a
                        href={link.url}
                        target="_blank"
                        data-testid={link.type}
                        aria-label={label}
                        rel="noreferrer"
                        className={
                          'inline-block focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-blackA20'
                        }
                      >
                        {icon}
                      </a>
                  </li>
                );
              })}
            </ul>
            <p
              data-testid={"copyright"}
              className={
                "typography-3xs-bold text-center uppercase mobile:pt-1 tablet:order-1"
              }
            >
              &copy; {currentYear} Choosing Therapy
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default PageFooter;
