{
  "buttons": {
    "delete": "Delete",
    "learn-more": "Learn more",
    "remove": "Remove",
    "save": "Save",
    "save-changes": "Save Changes",
    "save-continue": "Save and Continue",
    "saved": "Saved",
    "signin": "Sign In"
  },
  "errors": {
    "file-too-large": "The file size should not exceed {{limit}}MB",
    "file-type": "Please select a file of an allowed type"
  },
  "labels": {
    "add-photo": "Add Profile Photo",
    "add-gallery-photo": "Add Photo",
    "addressLine1": "Street Line 1",
    "addressLine2": "Street Line 2",
    "change-photo": "Change Photo",
    "city": "City",
    "contactNumber": "Cell Phone Number",
    "contactPhone": "Cell Phone Number",
    "email": "E-mail",
    "end-time": "end time",
    "error": "You have an error:",
    "ethnicity": "Ethnicity",
    "experience": "Experience",
    "faith": "Faith",
    "file-placeholder": "Choose file...",
    "firstName": "First Name",
    "gender": "Gender",
    "general-info": "General Info",
    "languages": "Languages",
    "lastName": "Last Name",
    "location": "location",
    "officePhone": "Office Phone Number",
    "optional": "optional",
    "password": "Password",
    "practice": "practice",
    "privateField": "not displayed publicly",
    "required": "required",
    "sexuality": "Sexuality",
    "start-time": "start time",
    "state": "State",
    "to": "to",
    "under-review": "Edit Submitted - Under Review",
    "under-review-expanded": "Your Edit is Submitted, now it is Under Review",
    "website": "Website",
    "zipCode": "Zip"
  },
  "phrases": {
    "all-required": "              All these fields are required!",
    "no": "No",
    "please-note": "Please note:",
    "yes": "Yes"
  },
  "prompts": {
    "saving-success": "Your changes have been successfully saved!",
    "saving-with-review": "Your changes have been successfully submitted! Some fields require manual review and approval and may take 2 business days to be reflected on your profile.",
    "something-went-wrong": "Oops! Something went wrong. Please, try again later.",
    "submitting": "Your data currently is submitting, wait a second.",
    "validation-errors": "Oops! Some fields need edits in order to be saved. Please review above."
  }
}
