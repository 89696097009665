import React, { useState } from "react";
import classNames from "classnames";
import copy from "copy-to-clipboard";
import ButtonInline from "~/UI/1-atoms/Buttons/ButtonInline";
import ImageForFileInput from "~/UI/2-molecules/ImageForFileInput";
import { useTranslation } from "react-i18next";

export interface SidebarProfileInfoProps {
  fullName: string;
  licenses: string[];
  primaryHeadshot?: string;
  status: string;
  profileLink: string;
}

const formattingLicenseName = (licensesArray: string[]) => {
  let uniqueLicenses = [...new Set(licensesArray)];
  return uniqueLicenses.join(", ");
};

function SidebarProfileInf({
  fullName,
  licenses,
  primaryHeadshot,
  status,
  profileLink,
  className,
  ...props
}: SidebarProfileInfoProps & React.HTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation();
  const [copyText, setCopyText] = useState<String>(t("sidebar:copy-profile"));
  const copyToClipboard = () => {
    copy(profileLink);
    setCopyText(t("sidebar:copied-profile"));
    setTimeout(() => {
      setCopyText(t("sidebar:copy-profile"));
    }, 1000);
  };
  return (
    <div className="mb-7 laptop:mb-4" {...props}>
      <div className="below-laptop:hidden">
        <p className={"typography-xs-bold hidden desktop:block"}>{fullName}</p>
        <p
          className={"typography-3xs-bold hidden text-black050 desktop:block"}
          data-testid="licenses"
        >
          {formattingLicenseName(licenses)}
        </p>
        <div
          className={"rounded mt-3 flex flex-row gap-2  mobile:justify-evenly"}
        >
          <div className="overflow-hidden rounded-lg">
            <ImageForFileInput
              url={primaryHeadshot || ""}
              fileName={`${fullName}-headshot`}
              showPlaceholder={true}
              altText={`${fullName}-headshot`}
              sidebar={true}
              className="h-[6.25rem] w-[6rem] rounded-lg bg-gray100"
            />
          </div>
          <div className="laptop:flex laptop:flex-col laptop:justify-between">
            <div className="desktop:hidden">
              <p className={"typography-base-bold desktop:hidden"}>
                {fullName}
              </p>
              <p className={"typography-3xs-bold text-black050 desktop:hidden"}>
                {formattingLicenseName(licenses)}
              </p>
            </div>
            <div
              className={classNames(
                "flex space-x-5 desktop:flex-col desktop:items-start desktop:space-x-0 desktop:space-y-4"
              )}
            >
              <p className={"typography-3xs-bold"}>
                {t("sidebar:status")}:{" "}
                <span
                  className={classNames(
                    `${
                      status === "APPROVED"
                        ? "text-purple100"
                        : status === "PENDING"
                        ? "text-black050"
                        : "text-red100"
                    }`
                  )}
                >
                  {status === "APPROVED"
                    ? t("sidebar:statuses.verified")
                    : status === "PENDING"
                    ? t("sidebar:statuses.pending")
                    : t("sidebar:statuses.rejected")}
                </span>
              </p>
              {status === "APPROVED" && (
                <>
                  <p className={"typography-3xs-bold cursor-pointer border-b "}>
                    <a
                      href={`${profileLink}`}
                      target={"_blank"}
                      rel={"noreferrer"}
                    >
                      {t("sidebar:see-profile")}
                    </a>
                  </p>
                  <ButtonInline
                    className={"typography-3xs-bold ml-auto cursor-pointer p-0"}
                    variant="underline"
                    onClick={copyToClipboard}
                  >
                    {copyText}
                  </ButtonInline>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className=" flex-row below-laptop:flex laptop:hidden desktop:hidden">
        <p className={"typography-3xs-bold mr-auto"}>
          {t("sidebar:status")}:{" "}
          <span
            className={classNames(
              `${
                status === "APPROVED"
                  ? "text-purple100"
                  : status === "PENDING"
                  ? "text-black050"
                  : "text-red100"
              }`
            )}
          >
            {status === "APPROVED"
              ? t("sidebar:statuses.verified")
              : status === "PENDING"
              ? t("sidebar:statuses.pending")
              : t("sidebar:statuses.rejected")}
          </span>
        </p>
        {status === "APPROVED" && (
          <>
            <p className={"typography-3xs-bold cursor-pointer border-b "}>
              <a href={`${profileLink}`} target={"_blank"} rel={"noreferrer"}>
                {t("sidebar:see-profile")}
              </a>
            </p>
            <ButtonInline
              className={"typography-3xs-bold ml-auto cursor-pointer p-0"}
              variant="underline"
              onClick={copyToClipboard}
            >
              {copyText}
            </ButtonInline>
          </>
        )}
      </div>
    </div>
  );
}

export default SidebarProfileInf;
